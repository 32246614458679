// Error.jsx
import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Error Page</h1>
      {/* Conteúdo da página de erro */}
    </div>
  );
}

export default ErrorPage;
